export const KIND = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
}

export const SHAPE = {
  default: "default",
  pill: "pill",
  circle: "circle",
}

export const TYPE = {
  button: "button",
  submit: "submit",
  reset: "reset",
}
