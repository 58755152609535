import { SizingT } from "../types"

const sizing: SizingT = {
  // 2px scale
  scale0: "2px",
  scale100: "4px",
  scale200: "6px",
  scale300: "8px",
  scale400: "10px",
  scale500: "12px",
  // 4px scale
  scale550: "14px",
  scale600: "16px",
  scale650: "18px",
  scale700: "20px",
  scale750: "22px",
  scale800: "24px",
  // 8px scale
  scale900: "32px",
  scale1000: "40px",
  // 4px scale
  scale1200: "48px",
  scale1400: "56px",
  scale1600: "64px",
  scale2400: "96px",
  scale3200: "128px",
  scale4800: "192px",
}

export default sizing
